import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const useGsapAnimation = (
  triggerSelector,
  para1Selector,
  para2Selector,
  imageSelector,
  start = "top 80%",
  end = "bottom 20%"
) => {
  const [windowwidth, setWindowWidth] = useState(
    window.innerWidth < 1060 ? false : true
  );

  function screencheck() {
    if (window.innerWidth < 1060) {
      setWindowWidth(false);
    } else {
      setWindowWidth(true);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", screencheck);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerSelector,
        start: start,
        end: end,
        toggleActions: "play none none none", // Adjust as needed
      },
      defaults: { duration: 1, ease: "power2.inOut" },
    });

    tl.fromTo(
      para1Selector,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 1, duration: 1 }
    )
      .fromTo(
        para2Selector,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 1, stagger: 0.3 }
      )
      .fromTo(
        imageSelector,
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0 },
        windowwidth ? "<-2" : "<"
      );

    return () => {
      window.removeEventListener("resize", screencheck);
    };
  }, [
    triggerSelector,
    para1Selector,
    para2Selector,
    imageSelector,
    start,
    end,
    windowwidth,
  ]);
};

export const useGsapimgAnimation = (
  triggerSelector,
  imageSelector,
  start = "top 80%",
  end = "bottom 20%"
) => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerSelector,
        start: start,
        end: end,
        toggleActions: "play none none none",
      },
      defaults: { duration: 1, ease: "power2.out" },
    });

    tl.fromTo(
      imageSelector,
      { scale: 0.7 },
      { scale: 0.9, stagger: 0.1, duration: 0.5 }
    );
  }, [triggerSelector, imageSelector, start, end]);
};
