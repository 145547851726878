import React, { useState } from "react";
import "./abstractsubmission.css"
const AbstractSubmission = () => {
  const departmentarr = [
    "Oral Medicine & Radiology",
    "Oral & Maxillofacial Surgery",
    "Prosthodontics Crown & Bridge",
    "Conservative & Endodontics",
    "Periodontics",
    "Orthodontics & Dentofacial",
    "Orthopedics",
    " Pediatric & Preventive Dentistry",
    "Oral Pathology",
    "Public Health Dentistry",
    "Multi-disciplinary",
  ];
  const PaperType = ["Original Research", "Case Report", "Review"];
  const [deptId, setDeptId] = useState("");
  const [dept, setDept] = useState("");
  const [paperType, setPaperType] = useState("");


  return (
    <div className="inputcontainer">
      <div className=" py-5 mt-5 d-flex flex-column align-items-center justify-content-center">
        <h1 className="text-center">Abstract Submission</h1>
        <form id="forminput" className="py-2 mt-3">
          <div className="form-group py-2">
            <label for="exampleFormControlInput1">
              Enter conference registration ID
            </label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleFormControlInput1"
              placeholder="Enter conference registration ID"
              onChange={(e) => setDeptId(e.target.value)}
            />
          </div>
          <div className="form-group py-3">
            <label for="exampleFormControlSelect1">Department</label>
            <select
              className="form-control mt-2"
              id="exampleFormControlSelect1"
              onChange={(e) => setDept(e.target.value)}
            >
              <option>Select Department</option>
              {departmentarr.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
          </div>
          <div className="form-group py-2">
            <label for="exampleFormControlSelect2">Scientific Paper</label>
            <select
              className="form-control mt-2"
              id="exampleFormControlSelect2"
              onChange={(e) => setPaperType(e.target.value)}
            >
              <option>Select Type of Scientific Paper</option>
              {PaperType.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
          </div>

          <button className="btn btn-success mt-4 w-100 " disabled type="submit">
            submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AbstractSubmission;
