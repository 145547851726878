import { useGsapAnimation, useGsapimgAnimation } from "../../utils/animations";
import React, { useRef } from "react";
import gsap from "gsap";
import "./about.css";
const About = () => {
  useGsapAnimation(
    ".about_section",
    ".animatepara1",
    ".animatepara2",
    ".animated_image",
    "top 80%",
    "bottom 20%"
  );
  useGsapAnimation(
    ".about_section2",
    ".abt2img",
    ".abt2para1",
    ".abt2para2",
    "top 80%",
    "bottom 20%"
  );
  useGsapimgAnimation(
    ".animatedimgs",
    ".animatedimgs",
    "top 80%",
    "bottom 30%"
  );
  const imgArr = [];
  const placename = [
    " ",
    "Marina Beach",
    "Ecr Beach",
    "Kailashnathar Temple",
    "Marundeeswarar Temple",
    "Loyola Church",
    "San Thomas Church",
    "The Big Temple of Tanjavore",
    "Pondicherry Auroville",
    "Mahabalipuram",
    "Cholamandal Artist Village",
    "Dhakshin Chitra House",
    "Streets Of Pondy",
    "Ocean Surfing",
    "Go-Karting",
    "Mangroove Forest",
    "Vedanthangal Bird Sanctuary",
    "Kanchipuram Skills",
    "Pondicherry White Town",
    "Keezhadi Civilisation",
    "Chettinad Palace",
    " Arignaranna Zoological Park",
    "Arulmigu Arunachaleshwarar Temple",
  ];
  for (let i = 1; i < 22; i++) {
    imgArr.push({
      src: `./img/home/places/${i}.png`,
      place: placename[i],
    });
  }
  const imgref = useRef([]);
  function mouseenters(i) {
    gsap.to(imgref.current[i], { scale: 1 });
  }
  function mouseleves(i) {
    gsap.to(imgref.current[i], { scale: 0.9 });
  }
  return (
    <>
      <section className="py-5 about_section">
        <div className="container-fluid col-lg-10 col-md-10 col-11">
          <div className="row d-flex flex-xl-row gap-5 flex-column justify-content-center">
            <div className="col-12 col-xl-5">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <h1 className="align-self-start">ABOUT SRMIST</h1>
                <div className="animatedpara">
                  <p className=" animatepara1 lh-lg fs-5 mt-3 para-txt">
                    Institute of Science and Technology (SRMIST), located in
                    Chennai, India, is one of the country’s premier private
                    educational institutions, known for its academic excellence,
                    world-class infrastructure, and vibrant campus life.
                    Established in 1985 as SRM Engineering College, the
                    institute has grown into a multidisciplinary university
                    offering a wide range of undergraduate, postgraduate, and
                    doctoral programs across various fields, including
                    engineering, medicine, management, science, law, and
                    humanities. SRMIST is renowned for its commitment to
                    academic excellence and innovation.
                  </p>
                  <p className=" animatepara1 lh-lg fs-5 para-txt">
                    SRMIST boasts a sprawling, modern campus that spans over 250
                    acres, offering students an ideal environment for learning
                    and personal growth. The campus is equipped with
                    state-of-the-art facilities, including well-designed
                    classrooms, advanced laboratories, libraries stocked with a
                    vast collection of books and digital resources, and research
                    centers that cater to various academic disciplines. The
                    campus also features auditoriums, seminar halls, and
                    conference rooms that host various academic, cultural, and
                    extracurricular events throughout the year.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <img
                  src="./img/home/college.png"
                  className="animated_image"
                  alt=""
                  width="80%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 about_section2">
        <div className="container-fluid col-lg-10 col-md-10 col-11">
          <div className="row d-flex flex-sm-row-reverse gap-5 flex-column justify-content-center">
            <div className="col-12 col-xl-5">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <h1 className="align-self-start">ABOUT CHENNAI</h1>
                <div>
                  <p
                    className="lh-lg fs-5 mt-3 para-txt abt2para1"
                    style={{ textAlign: "justify" }}
                  >
                    Chennai is a vibrant city that lies on the Coromandel Coast
                    of the Bay of Bengal in south-eastern India. It is the
                    capital of Tamil Nadu, a state rich in culture, heritage and
                    history. Chennai has a diverse population that reflects its
                    colonial past, cosmopolitan present and promising future.
                  </p>
                  <p className="lh-lg fs-5 para-txt abt2para1">
                    The city boasts of many attractions, such as the Marina
                    Beach, the longest urban beach in India; the Government
                    Museum, one of the oldest museums in the country; the
                    Santhome Cathedral Basilica, built over the tomb of St.
                    Thomas the Apostle; and the Guindy National Park, a rare
                    protected area within a metropolis. Chennai is also known
                    for its classical music and dance festivals, its delicious
                    cuisine and its thriving film industry. Chennai is a city
                    that welcomes visitors with its warmth, charm and
                    hospitality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <img
                  src="./img/home/chennai.png"
                  className="abt2img"
                  alt=""
                  width="80%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid my-5 py-5 col-lg-10 col-md-10 col-11">
        <h1 className="text-center py-4 my-3">Places Around Chennai</h1>
        <div className="row mt-4 py-4" id="imageGrid">
          {imgArr.map((data, i) => {
            return (
              <div
                key={i}
                className="flex align-items-center justify-content-center flex-column col-12 col-md-6 col-lg-4"
              >
                <img
                  key={i}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => mouseenters(i)}
                  onMouseLeave={() => mouseleves(i)}
                  ref={(el) => (imgref.current[i] = el)}
                  className="mx-auto rounded img-fluid d-block center-block animatedimgs"
                  width="80%"
                  src={data.src}
                  alt=""
                />
                <p className="text-center fs-4 py-2">{data.place}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
export default About;
