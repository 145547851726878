export const Hotel = [
  {
    id: 1,
    hotelName: "Ramada by Wyndham",
    Location: "POTHERI",
    distance: "0.0",
    phoneNum: "+91 73974 87544",
    Link: "https://www.srmhotels.com/",
    img: "./img/Accommodaiton/hotels/1.png",
  },
  {
    id: 2,
    hotelName: "V FIVE - Alma",
    Location: "KATTANKULATHUR",
    distance: "2.8",
    phoneNum: "+91 86675 22570",
    Link: "https://www.vfivehotel.com/",
    img: "./img/Accommodaiton/hotels/2.png",
  },
  {
    id: 3,
    hotelName: "Hotel Bakya slot",
    Location: "MM NAGAR ",
    distance: "3.6",
    phoneNum: "+91 73050 81971",
    Link: "https://www.bakyaslot.com/",
    img: "./img/Accommodaiton/hotels/3.png",
  },
  {
    id: 4,
    hotelName: "Hotel SRR Grand",
    Location: "VANDALUR",
    distance: "8.7",
    phoneNum: "+91 44227 50096",
    Link: "https://www.makemytrip.com/hotels/hotel_srr_grand_vandalur-details-chennai.html",
    img: "./img/Accommodaiton/hotels/4.png",
  },
  {
    id: 5,
    hotelName: "HOTEL HIGHNEST",
    Location: "MAHENDRA CITY",
    distance: "6.9",
    phoneNum: "+91 98400 59095",
    Link: "http://highnest.com/",
    img: "./img/Accommodaiton/hotels/5.png",
  },
  {
    id: 6,
    hotelName: "WELCOME HOTEL",
    Location: "SP KOVIL",
    distance: "7.8",
    phoneNum: "+91 44674 14243",
    Link: "https://www.itchotels.com/in/en/welcomhotelgstroad-chennai?utm_source=google&utm_medium=businesslisting&utm_campaign=Welcomhotel_GST_Road_Chennai",
    img: "./img/Accommodaiton/hotels/6.png",
  },
  {
    id: 7,
    hotelName: "MARRIOTT",
    Location: "MAHENDRA CITY",
    distance: "12.0",
    phoneNum: "+91 44454 06699",
    Link: "https://www.marriott.com/en-us/hotels/maafh-fairfield-chennai-mahindra-world-city/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0",
    img: "./img/Accommodaiton/hotels/7.png",
  },
  {
    id: 8,
    hotelName: "LIFEOTEL SUITES",
    Location: "GUDUVANCHERRY",
    distance: "5.0",
    phoneNum: "+91 75320 20204",
    Link: "https://kkinn.in/#loca",
    img: "./img/Accommodaiton/hotels/8.png",
  },
  {
    id: 9,
    hotelName: "SANNS TROPICANA",
    Location: "GUDUVANCHERRY",
    distance: "3.5",
    phoneNum: "+91 7448939627",
    Link: "https://www.sannstropicana.com/booking/",
    img: "./img/Accommodaiton/hotels/9.png",
  },
  {
    id: 10,
    hotelName: "DOWNTOWN BUISSNESS",
    Location: "MAHENDRA CITY",
    distance: "12.0",
    phoneNum: "+91 7550087250",
    Link: "https://www.downtownmwc.com/",
    img: "./img/Accommodaiton/hotels/10.png",
  },
  {
    id: 11,
    hotelName: "HYTON GRAND",
    Location: "URAPAKKAM",
    distance: "7.0",
    phoneNum: "+91 7010709797",
    Link: "https://www.google.com.my/travel/hotels/entity/CgsIz577oIWqiISpARAB?utm_campaign=sharing&utm_medium=link&utm_source=htls&ved=0CAAQ5JsGahcKEwj4q8zi5LSGAxUAAAAAHQAAAAAQBA&ts=CAEaIAoCGgASGhIUCgcI6A8QBRgfEgcI6A8QBhgBGAEyAhAAKgkKBToDSU5SGgA",
    img: "./img/Accommodaiton/hotels/11.png",
  },
  {
    id: 12,
    hotelName: "GRK RESIDENCY",
    Location: "GUDUVANCHERRY",
    distance: "5.5",
    phoneNum: "+91 8754453512",
    Link: "https://grk-residency.hotelsintamilnadu.com/en/",
    img: "./img/Accommodaiton/hotels/12.png",
  },
];
