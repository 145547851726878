import { Link } from "react-router-dom";
const Registration = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>This Page is UnderConstruction</h1>
      <p>
        <Link to={"/"}>Visit our Home Page</Link>
      </p>
    </div>
  );
};

export default Registration;
