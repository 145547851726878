import CommitteeMembers from "../../component/committee/CommitteeMembers";

const Committee = () => {
  return (
    <>
    <CommitteeMembers/>
    </>
  );
};

export default Committee;
