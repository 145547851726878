import React, { useState } from "react";
import "./abstractsubmission.css"

const CaseReport = () => {
  const [Intro, setIntro] = useState("");
  const [aim, setAim] = useState("");
  const [material, setMaterials] = useState("");
  const [results, setResults] = useState("");
  const [conclusion, setConclusion] = useState("");

  console.log({ Intro, aim, material, results, conclusion });

  return (
    <div className="inputcontainer py-5">
     
      <div className="py-3 mt-1 d-flex flex-column align-items-center justify-content-center">
      <h1 className="text-center">Case Report</h1>
        <form id="forminput"className="py-2">
          <div className="form-group mt-3">
            <label for="exampleInputEmail1">Introduction</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInputEmail1"
              placeholder="Introduction"
              onChange={(e) => setIntro(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label for="exampleInput2">Aims & Objective</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInput2"
              placeholder="Aims & Objective"
              onChange={(e) => setAim(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label for="exampleInput3">Materials & Methods</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInput3"
              placeholder="Materials & Methods"
              onChange={(e) => setMaterials(e.target.value)}
            />
          </div>

          <div className="form-group mt-3">
            <label for="exampleInput4">Results</label>
            <input
              type="text"
              className="form-control mt-2"
              id="exampleInput4"
              placeholder="Results"
              onChange={(e) => setResults(e.target.value)}
            />
          </div>
          <div class="form-group mt-3">
            <label for="exampleFormControlTextarea1">Conclusion</label>
            <textarea
              class="form-control mt-2"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) => setConclusion(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" disabled className="btn btn-success w-100 mt-4">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CaseReport;
